import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page not found" />
    <div className="fs" style={{
      height: '90vh'
    }}>
      <div className="fs flex-center md has-links">
        <center>
          <h3>Page not found...</h3>
          <p>See our latest products on the <Link to="/">Homepage</Link> or read more <Link to="about">About Us</Link> and our <Link to="/bespoke-services">Bespoke Services</Link>.</p>
          <p>If you still can't find what you are looking for, email us: <a href="mailto:info@sedilia.com">info@sedilia.com</a></p>
        </center>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
